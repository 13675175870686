<template>
	<div v-if="!loading" class="flex flex-row flex-wrap justify-start items-start gap-1 w-full px-6 py-12">
		<!-- Назад -->
		<a class="flex h-6 mb-9 text-sm font-semibold text-blue-500 blue-arrow cursor-pointer" @click.prevent="$router.go(-1)"> Назад </a>
		<h1 class="flex w-full text-xl h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">{{ getInfoAnket.titleBlock }}</h1>
		<!-- Блоки -->
		<Form />

		<btn-arrow-up v-show="height > 500" @click="toTop" class="fixed bottom-12 right-6 cursor-pointer" />
	</div>
</template>

<script>
// Локальные данные стора
import { actions, getters, methods } from '@/store/store';
import Form from './Form.vue';
import btnArrowUp from '@/assets/img/arrow_up.svg';

export default {
	name: 'Main',
	components: { Form, btnArrowUp },
	computed: {
		...getters
	},
	data: () => ({
		loading: true,
		height: 0
	}),
	methods: {
		...actions,
		...methods,
		calcDoneMOPercent(a, b) {
			return Math.floor((a / b) * 100) || '0';
		},
		calcProgressBarWidth(percent) {
			return `width: ${percent}%`;
		},
		handleScroll() {
			this.height = window.scrollY;
		},
		toTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	async created() {
		await this.getAnketa(this.$route.params.anketaResultId, true).then((res) => {
			console.log(res);
			if (res.status === 200) this.loading = false;
		});
	}
};
</script>

<style scoped>
.blue-arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-blue-500;
	content: '';
	transform: rotate(45deg);
}

.round-box {
	@apply flex px-10 py-8 rounded-lg filter drop-shadow-default;
}

.progress-bar {
	@apply flex flex-grow w-full h-2 max-h-2 rounded relative bg-gray-200;
	z-index: 10;
}

.progress-tracker {
	@apply flex h-2 rounded;
	width: 20%;
	z-index: 20;
}
</style>
