<template>
	<div class="flex flex-col flex-grow w-full">
		<ul class="flex flex-col w-full mt-4 text-sm text-gray-600 rounded-lg bg-white filter shadow-default overflow-hidden" v-for="(el, key) in dataAnket" :key="key">
			<li class="questions-row bg-blue-500 text-white">
				<!-- <p>Вопрос</p> -->
				<p class="w-full font-bold text-white">
					{{ el.comment }}
				</p>
			</li>
			<ul class="w-full border-t border-gray-100 last-no-border" v-for="(item, ind) in el" :key="ind">
				<li class="questions-row">
					<div class="w-3/5" :class="{ 'pl-6': !item.isParent }">{{ item.numberTitle || ind + 1 }}. {{ item.title }}</div>

					<!-- Редактируем -->
					<div class="flex flex-wrap content-center ml-auto w-1/4">
						<div v-for="(it, key) in item.answers" :key="'A' + key">
							<!-- RADION BTN -->
							<span v-if="it.type === 3">
								<!-- Да/Нет -->
								<span v-if="item.answers.length === 1" class="ml-6">
									<label class="inline-flex items-center cursor-pointer">
										<input type="radio" class="w-5 h-5" :name="'accountType' + it.id" v-model="item.value" value="true" @change="inlineSave(item.id, it.dependencyQuestions)" />
										<span class="ml-2">Да</span>
									</label>

									<label class="inline-flex items-center mt-0 ml-6 cursor-pointer">
										<input type="radio" class="w-5 h-5" :name="'accountType' + it.id" v-model="item.value" value="false" @change="inlineSave(item.id, it.dependencyQuestions)" />
										<span class="ml-2">Нет</span>
									</label>
								</span>
								<!-- Остальное -->
								<span v-else>
									<label class="inline-flex items-center ml-6 cursor-pointer">
										<input
											@change="inlineSave(item.id, it.dependencyQuestions)"
											type="radio"
											class="w-5 h-5"
											v-model="item.value"
											:name="'accountType' + item.id"
											:value="it.value ? it.value : key === 0 ? 'true' : 'false'"
										/>
										<span class="ml-2">
											{{ it.title }}
										</span>
									</label>
								</span>
							</span>

							<!-- NUMBER -->
							<span v-if="it.type === 2">
								<input
									type="number"
									class="focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full sm:w-1/4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
									:class="{
										'border-red-500 focus-visible:border-red-500 focus-visible:ring-red-500 dark:focus-visible:ring-red-500 dark:focus-visible:border-red-500':
											item.value < 0 || item.value > 10,
										'border-blue-500 focus-visible:border-blue-500 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-500 dark:focus-visible:border-blue-500':
											item.value > 0 && item.value <= 10
									}"
									v-model="item.value"
									v-filter="'[0-9.]'"
									@input="saveDebounceTN(item.value, 'number')"
								/>
							</span>

							<!-- TEXT -->
							<span v-if="it.type === 1">
								<input
									type="text"
									class="focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									v-model="item.value"
									@input="saveDebounceTN(item.value, 'string')"
								/>
							</span>
						</div>
					</div>
				</li>
			</ul>
		</ul>
		<div class="flex justify-end mt-2">
			<Button text="Отправить" @click="saveBtn()" />
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import { actions, getters, mutations } from '@/store/store';
import Button from '@/components/Button.vue';

export default {
	name: 'Form',
	components: {
		Button
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		...mutations,
		saveDebounceTN: debounce(function (value, type) {
			if (type === 'number' && (value < 0 || value > 10)) {
				this.$toast.warning('Можно вводить числа в диапазоне от 0 до 10', {
					position: 'top-right',
					timeout: 3000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: 'button',
					icon: true,
					rtl: false
				});

				return;
			}
		}, 1000),
		inlineSave(questionId, dependencyQuestions) {
			let dependency = [];

			dependencyQuestions.forEach((item) => {
				dependency.push(item.dependencyQuestionId);
			});

			this.mutationDependencyQuestions(questionId, dependency);
		},
		saveOne(anketaQuestionId, anketaAnswerId, value, anketaId) {
			this.$http.post('/Anketa/PostAnswer', {
				anketaQuestionId,
				anketaAnswerId,
				value,
				anketaId
			});
		},
		saveBtn() {
			this.dataAnket.forEach((item) => {
				item.forEach((it) => {
					if (it.value) {
						it.answers.forEach((answer) => {
							this.saveOne(answer.anketaQuestionId, answer.id, it.value, this.$route.params.anketaResultId);
						});
					}
				});
			});

			this.$toast.success('Ответы успешно сохранены!', {
				position: 'top-right',
				timeout: 2000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});

			this.resetAnket();
		}
	}
};
</script>

<style>
.questions-row {
	/* @apply flex w-full px-7 py-5 border-t border-gray-100; */
	@apply flex w-full px-7 py-5;
}

.questions-row:nth-child(2) {
	@apply font-semibold;
}

.questions-row:nth-child(3) {
	@apply bg-gray-100;
}

.questions-answer {
	@apply flex;
}
</style>
